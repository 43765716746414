/* eslint-disable prettier/prettier */
<template>
    <v-app>
        <v-navigation-drawer v-model="controls.drawer" width="290" floating app>
            <v-layout
                align-center
                justify-center
                column
                class="app-logo-container"
                style="height: 322px"
            >
                <div class="app-logo-sheet">
                    <v-img
                        :src="require('@/assets/images/logo.svg')"
                        width="74px"
                        height="74px"
                        class="ma-6"
                        style="filter: contrast(0.8)"
                    ></v-img>
                </div>
                <div class="app-company--name mt-8">City of Stonnington</div>
                <div class="app-project--name mt-2">Admin CMS</div>
            </v-layout>
            <v-list class="app-nav" flat>
                <v-subheader class="mb-5">
                    <div class="app-nav--title mx-auto">NAVIGATION</div>
                </v-subheader>
                <v-list>
                    <template v-for="(item, itemIndex) in navItems">
                        <v-divider
                            v-if="item.children && item.active"
                            :key="`${itemIndex}-first-divider`"
                        />
                        <v-list-group
                            v-if="item.children"
                            :key="itemIndex"
                            v-model="appManagementMenuState"
                            class="default-color"
                        >
                            <template v-slot:activator>
                                <v-list-item-content>
                                    <v-list-item-title
                                        class="pa-2 nav-item"
                                        v-text="item.label"
                                    />
                                </v-list-item-content>
                            </template>
                            <v-list-item
                                class="app-main-nav"
                                v-for="(subItem,
                                subItemmIndex) in item.children"
                                :key="subItemmIndex"
                                :to="subItem.to"
                                v-ripple="{ class: 'primary--text lighten-4' }"
                                ripple
                                link
                            >
                                <v-list-item-content>
                                    <v-list-item-title
                                        class="pa-2 pl-5 nav-item"
                                        v-text="subItem.title"
                                    />
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-group>
                        <v-list-item
                            v-else
                            class="app-main-nav"
                            :key="`${itemIndex}`"
                            :to="item.to"
                            v-ripple="{ class: 'primary--text lighten-4' }"
                            link
                        >
                            <v-list-item-content>
                                <v-list-item-title class="pa-2 nav-item">
                                    {{ item.label }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider
                            v-if="item.children && item.active"
                            :key="`${itemIndex}-second-divider`"
                        />
                    </template>
                </v-list>
            </v-list>
            <template v-slot:append>
                <v-list>
                    <v-menu top offset-y>
                        <template v-slot:activator="{ on }">
                            <v-list-item two-line v-on="on">
                                <v-list-item-avatar>
                                    <user-avatar size="40" :avatar="null" />
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>{{
                                        user.full_name
                                    }}</v-list-item-title>
                                    <v-list-item-subtitle>
                                        {{
                                            user.email
                                                ? user.email
                                                : user.phone_number
                                        }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                        <v-list>
                            <v-list-item
                                :to="{
                                    name: 'admin.details',
                                    params: { id: user.id }
                                }"
                            >
                                <v-list-item-title>Profile</v-list-item-title>
                            </v-list-item>
                            <v-list-item :to="{ name: 'account.settings' }">
                                <v-list-item-title>Settings</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="logoutConfirmDialog = true">
                                <v-list-item-title>Log out</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-list>
            </template>
        </v-navigation-drawer>
        <v-content class="main-content">
            <div class="px-xl-12 px-lg-10 px-md-6 px-sm-4 px-xs-2">
                <slot />
            </div>
        </v-content>
        <v-dialog v-model="logoutConfirmDialog" max-width="290">
            <v-card>
                <v-card-title class="headline">Logout</v-card-title>
                <v-card-text>
                    <strong>Are you sure you want to logout ?</strong>
                </v-card-text>
                <v-card-actions>
                    <div class="flex-grow-1"></div>
                    <v-btn text @click="logoutConfirmDialog = false"
                        >Cancel</v-btn
                    >
                    <v-btn color="primary" text @click="logout">Logout</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-app>
</template>
<script>
import ControlsMixin from '@/utils/mixins/Controls'
import { mapState, mapActions, mapGetters } from 'vuex'
import UserAvatar from '@/components/UserAvatar'
export default {
    name: 'MainLayout',
    components: {
        UserAvatar
    },
    mixins: [ControlsMixin],
    data() {
        return {
            navItems: [
                {
                    label: 'Bin days',
                    to: { name: 'bindays' },
                    active: true
                },
                {
                    label: 'App Management',
                    children: [
                        {
                            title: 'Dynamic Buttons (Home)',
                            to: 'buttons',
                            active: true
                        },
                        {
                            title: 'Bin details',
                            to: 'bindetails',
                            active: true
                        }
                    ]
                },
                {
                    label: 'Admin Management',
                    to: { name: 'admins' }
                }
            ],
            logoutConfirmDialog: false,
            appManagementMenuState: false,
            appManagementMenuRoutes: ['buttons', 'bindetails']
        }
    },
    computed: {
        ...mapState({
            user: state => state.auth.user
        }),
        ...mapGetters({
            avatarThumb: 'auth/avatarThumb'
        })
    },
    methods: {
        ...mapActions({
            logout: 'auth/logout'
        })
    },
    created() {
        if (
            this.appManagementMenuRoutes.includes(
                this.$router.history.current.name
            )
        )
            this.appManagementMenuState = true
        else this.appManagementMenuState = false
    }
}
</script>
<style scoped>
.default-color {
    color: rgba(0, 0, 0, 0.87) !important;
}
</style>
